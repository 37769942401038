import { Link } from 'gatsby';
import React from 'react';

interface SideBySideFeaturesSectionProps {
  title: string;
  features: {
    title: string;
    body: string;
    cta: { label: string; url: string };
    imageUrl: string;
  };
}

const SideBySideFeaturesSection: React.FC<SideBySideFeaturesSectionProps> = ({
  title,
  features,
}) => {
  return (
    <div className="u-section u-container px-6 lg:px-md">
      <h2 className="u-h2 text-center mb-12 md:mb-24 w-110 max-w-full mx-auto">
        {title}
      </h2>
      <div className="flex flex-col md:flex-row">
        <div className="flex-1">
          <img
            src={features[0].imageUrl}
            alt={features[0].title}
            className="w-full h-auto"
          />

          <div className="mt-11 text-center md:hidden">
            <h3 className="mb-3.5 u-h3">{features[0].title}</h3>
            <p className="text-lg mb-4 text-paragraph mx-auto max-w-md">
              {features[0].body}
            </p>
            <Link to={features[0].cta.url} className="u-cta-link">
              {features[0].cta.label}
            </Link>
          </div>
        </div>
        <div className="md:w-6 h-20 flex-grow-0 md:h-auto hidden md:flex items-center justify-center">
          <div className="w-16 h-16 bg-white rounded-full absolute -m-5 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              fill="none"
              viewBox="0 0 17 17"
            >
              <path
                fill="#00B481"
                d="M16.496 6.473h-5.734V.432H6.414v6.041H.68v3.887h5.734V16.4h4.348V10.36h5.734V6.473z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex-1 mt-12 md:mt-0">
          <img
            src={features[1].imageUrl}
            alt={features[1].title}
            className="w-full h-auto"
          />

          <div className="mt-11 text-center md:hidden">
            <h3 className="mb-3.5 u-h3">Engage for Brands</h3>
            <p className="text-lg mb-4 text-paragraph mx-auto max-w-md">
              Then, execute smarter promos using knowledge gained from Insights.
              Sell more while perfecting your process.
            </p>
            <Link to="/enterprise/engage" className="u-cta-link">
              Learn more
            </Link>
          </div>
        </div>
      </div>

      <div className="hidden md:flex flex-row">
        <div className="flex-1">
          <div className="mt-11 text-center">
            <h3 className="mb-3.5 u-h3">Insights</h3>
            <p className="text-lg mb-4 text-paragraph mx-auto max-w-lg">
              First, gain visibility into consumer behavior and true product
              performance to optimize your convenience strategy.
            </p>
            <Link to="/enterprise/insights" className="u-cta-link">
              Learn more
            </Link>
          </div>
        </div>
        <div className="flex-1 w-6 flex-grow-0"></div>
        <div className="flex-1">
          <div className="mt-11 text-center">
            <h3 className="mb-3.5 u-h3">{features[1].title}</h3>
            <p className="text-lg mb-4 text-paragraph mx-auto max-w-lg">
              {features[1].body}
            </p>
            <Link to={features[1].cta.url} className="u-cta-link">
              {features[1].cta.label}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBySideFeaturesSection;
