import React from 'react';
import { html2react } from 'helpers/parser';

interface KPI {
  value: string;
  label: string;
}

interface KPISectionProps {
  title: string;
  kpis: KPI[];
}

const KPISection: React.FC<KPISectionProps> = ({ title, kpis }) => {
  return (
    <div className="container max-w-screen-xl mx-auto my-20 md:my-32 px-6 lg:px-md">
      <h2 className="text-center u-h2 max-w-full mx-auto mb-12 lg:mb-28">
        {html2react(title)}
      </h2>

      <div className="flex justify-between text-center flex-wrap -mb-8 md:mb-0">
        {kpis.map((kpi, index) => (
          <div key={index} className="w-6/12 md:w-auto md:flex-1 mb-8 md:mb-0">
            <div className="font-semibold text-4xl lg:text-6xl">
              {kpi.value}
            </div>
            <div className="opacity-60 font-semibold md:text-lg tracking-tightest">
              {kpi.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KPISection;
