import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import Feature from 'components/molecules/Feature';
import BenefitsSection from 'components/organisms/BenefitsSection';
import ConsumerRelationshipsSection from 'components/organisms/ConsumerRelationshipsSection';
import CtaSection from 'components/organisms/CtaSection';
import Hero from 'components/organisms/AnimationHero';
import FeaturesSection from 'components/organisms/FeaturesSection';
import NetworkMap from 'components/organisms/NetworkMap';
import KPISection from 'components/organisms/KPISection';
import SideBySideFeaturesSection from 'components/organisms/SideBySideFeaturesSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import Animation from '../../animations/brands-hero.json';

const leadColor = ['text-orange', 'text-aqua'];

const EnterpriseForBrandsContent = ({
  hero,
  kpi,
  features,
  benefits,
  testimonial,
  benefit,
  buildRelationships,
  sideBySideBenefits,
  cta,
}) => {
  return (
    <>
      <Hero
        breadcrumb="Brands"
        heading={hero?.title}
        content={hero?.description}
        button={{
          to: hero?.primaryCta?.url,
          label: hero?.primaryCta?.label,
        }}
        animationData={Animation}
      />

      <KPISection title={kpi.title} kpis={kpi?.kpis} />

      <FeaturesSection className="py-10 mt-20 mb-0 bg-tile md:my-32">
        {features?.features?.map(({ title, body, image }, index) => (
          <Feature key={index} title={title} body={body} image={image} />
        ))}
      </FeaturesSection>

      <NetworkMap />
      {/* <ConvenienceNetworkSection
title="Access America’s largest convenience store network"
description="Understand the true sales performance of 14,000+ independent convenience stores across the nation."
/> */}

      <BenefitsSection>
        {benefits?.benefits?.map(
          ({ lead, title, body, cta, image, imageSide }, index) => (
            <Benefit
              key={index}
              centered
              title={title}
              body={body}
              cta={{ to: cta?.url, label: cta?.label }}
              image={image}
              imageSide={imageSide}
              lead={lead}
              leadColor={leadColor[index % 2] as any}
            />
          ),
        )}
      </BenefitsSection>
      <TestimonialSection
        quote={testimonial?.quote}
        ctaLabel="Read customer story"
        className="bg-dark-grey"
        inverted
        hasRectangle
      />
      <BenefitsSection>
        <Benefit
          title={benefit?.title}
          body={benefit?.body}
          image={benefit?.image}
          imageSide={benefit?.imageSide}
          margin={false}
        />
      </BenefitsSection>

      <ConsumerRelationshipsSection
        title={buildRelationships?.title}
        body={buildRelationships?.body}
      />

      <div className="border-b border-border-grey" />

      <SideBySideFeaturesSection
        title={sideBySideBenefits?.title}
        features={sideBySideBenefits?.benefits?.map(
          ({ title, body, cta, image }) => ({
            title: title,
            body: body,
            cta: cta,
            imageUrl: image,
          }),
        )}
      />

      <CtaSection headline={cta?.headline} />
    </>
  );
};

export const EnterpriseForBrandsPreview = ({ entry }) => (
  <EnterpriseForBrandsContent {...entry.get('data').toJS()} />
);

const EnterpriseForBrandsPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <EnterpriseForBrandsContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query EnterpriseForBrandsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/enterprise/index/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
        }
        kpi {
          title
          kpis {
            label
            value
          }
        }
        features {
          features {
            title
            body
            image
          }
        }
        benefits {
          benefits {
            lead
            title
            body
            lead
            cta {
              label
              url
            }
            image
            imageSide
          }
        }
        testimonial {
          quote
        }
        benefit {
          title
          body
          image
          imageSide
        }
        buildRelationships {
          title
          body
        }
        sideBySideBenefits {
          title
          benefits {
            title
            body
            cta {
              label
              url
            }
            image
          }
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default EnterpriseForBrandsPage;
